import React from "react";
import Navbar from "../components/navbar";

const Services = () => {
  return (
    <>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <h1>Services</h1>
      </div>
    </>
  );
};

export default Services;
