import React from "react";
import Navbar from "../components/navbar";

const Home = () => {
  return (
    <>
      <Navbar />
      <div>
        <div class="main-div main-div-section main-div-container">
          <div class="welcome-title">Welcome to Beginupp</div>

          <h1 className="main-heading">The Home of Tech.</h1>

          <p class="subtitle">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>

        {/* <div class="second-div main-div-section main-div-container">
          <p class="subtitle">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div> */}
        {/*       
      <div>
      <img class="bg" src={bg} alt="bg-img"/>
      </div> */}
      </div>
    </>
  );
};

export default Home;
