import React from "react";
import Navbar from "../components/navbar";

const SignUp = () => {
  return (
    <>
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <h1>Sign Up</h1>
      </div>
    </>
  );
};

export default SignUp;
