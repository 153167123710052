import React from "react";
import Footer from "../components/footer";
import { BsTwitter, BsYoutube, BsInstagram } from "react-icons/bs";

export function FooterContainer() {
  return (
    <Footer>
      <Footer.Row>
        <Footer.Column>
          <Footer.Title>About Us</Footer.Title>
          <Footer.Link href="#">Story</Footer.Link>
          <Footer.Link href="#">Clients</Footer.Link>
          <Footer.Link href="#">Testimonials</Footer.Link>
        </Footer.Column>
        <Footer.Column>
          <Footer.Title>Services</Footer.Title>
          <Footer.Link href="#">Marketing</Footer.Link>
          <Footer.Link href="#">Consulting</Footer.Link>
          <Footer.Link href="#">Development</Footer.Link>
          <Footer.Link href="#">Design</Footer.Link>
        </Footer.Column>
        <Footer.Column>
          <Footer.Title>Contact Us</Footer.Title>
          <Footer.Link href="#">United States</Footer.Link>
          <Footer.Link href="#">United Kingdom</Footer.Link>
          <Footer.Link href="#">Australia</Footer.Link>
          <Footer.Link href="#">Support</Footer.Link>
        </Footer.Column>
        <Footer.Column>
          <Footer.Title>Social</Footer.Title>
          <Footer.Link href="#">Facebook</Footer.Link>
          <Footer.Link href="#">Instagram</Footer.Link>
          <Footer.Link href="#">Youtube</Footer.Link>
          <Footer.Link href="#">Twitter</Footer.Link>
        </Footer.Column>
      </Footer.Row>

      <div className="footer_bottom_copy">
        <Footer.Link>&copy; Beginupp, All rights reserved.</Footer.Link>
        <nav
          style={{
            display: "flex",
            flexDirection: "row",
            fleWrap: "wrap",
            justifyContent: "center",
            alignIitems: "center",
            textAlign: "center",
          }}
        >
          <a
            href="/"
            style={{
              opacity: ".5",
              padding: "0.5rem",
              cursor: "pointer",
            }}
          >
            <div>
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <BsTwitter
                  style={{
                    color: "#fff",
                  }}
                />
              </button>
            </div>
          </a>
          <a
            href="/"
            style={{
              opacity: ".5",
              cursor: "pointer",
              padding: "0.5rem",
            }}
          >
            <div>
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <BsYoutube
                  style={{
                    color: "#fff",
                  }}
                />
              </button>
            </div>
          </a>{" "}
          <a
            href="/"
            style={{
              padding: "0.5rem",
              cursor: "pointer",
              opacity: ".5",
            }}
          >
            <div>
              <button
                style={{
                  background: "none",
                  cursor: "pointer",
                  border: "none",
                }}
              >
                <BsInstagram
                  style={{
                    color: "#fff",
                  }}
                />
              </button>
            </div>
          </a>{" "}
          <a
            href="/"
            style={{
              padding: "0.5rem",
              opacity: ".5",
              cursor: "pointer",
            }}
          >
            <div>
              <button
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <BsTwitter
                  style={{
                    color: "#fff",
                  }}
                />
              </button>
            </div>
          </a>
        </nav>
      </div>
    </Footer>
  );
}
