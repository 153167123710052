import React from "react";
import logo from "../../images/newlogo.svg";
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  LogoLink,
  NavBtnLink,
} from "./navbarElements";
import { HiArrowNarrowRight } from "react-icons/hi";
import "./style.css";

const Navbar = () => {
  return (
    <>
      <Nav>
        <LogoLink to="/">
          <img src={logo} alt="logo" />
        </LogoLink>
        <Bars />
        <NavMenu>
          <NavLink to="/about" className="nav_link">
            About
          </NavLink>
          <NavLink to="/services" className="nav_link">
            Services
          </NavLink>
          <NavLink to="/contact-us" className="nav_link">
            Contact Us
          </NavLink>
        </NavMenu>
        <NavBtn>
          <NavBtnLink to="/" className="exploreBtn">
            Explore <HiArrowNarrowRight style={{ verticalAlign: "middle" }} />
          </NavBtnLink>
        </NavBtn>
      </Nav>
    </>
  );
};

export default Navbar;
