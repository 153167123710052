import styled from "styled-components";

export const Container = styled.div`
  padding: 10px 10px;
  background: #000;
  display: grid;

  @media screen and (min-width: 768px) {
    padding: 30px 60px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  /* background: red; */
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 2rem 0;

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    margin-left: auto;
    margin-right: auto;
    width: 17rem;
  }
`;

export const Link = styled.a`
  color: #666;
  margin-bottom: 20px;
  font-size: 1rem;
  text-decoration: none;
  font-weight: 400;
  letter-spacing: 0em;

  &:hover {
    color: #722741;
    transition: 200ms ease-in;
  }
  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`;

export const Title = styled.p`
  font-size: 1rem;
  letter-spacing: calc(-0.01239em - 0.01036vw + 0.03315px);
  color: #fff;
  margin-bottom: 1rem;
  font-weight: 530;

  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`;
